import React, { FC, useState, useEffect } from "react";
import { useAppSelector } from "app/hooks";
import { PostDataType } from "data/types";
import PostCardLikeAction, {
  PostCardLikeActionProps,
} from "components/PostCardLikeAction/PostCardLikeAction";
import { toggleLike, getUserLikeStatus } from "app/blogs/blogs";
import { Alert } from "components";
import Loader from "components/Loader";

export interface PostCardLikeContainerProps
  extends Omit<PostCardLikeActionProps, "isLiked" | "likeCount"> {
  like: PostDataType["like"];
}

const PostCardLikeContainer: FC<PostCardLikeContainerProps> = ({
  like,
  postId,
  onClickLike,
  ...args
}) => {
  const { user, token } = useAppSelector((state) => state.auth);
  const isLoggedIn = Boolean(user && token);
  const [isLikedState, setIsLikedState] = useState<boolean>(false);
  const [likeCountState, setLikeCountState] = useState<number>(like.count);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchLikeStatus = async () => {
      if (isLoggedIn) {
        setLoading(true);
        try {
          const userLikeStatus = await getUserLikeStatus(String(postId));
          setIsLikedState(userLikeStatus);
        } catch (err) {
          setError("Failed to fetch like status.");
        } finally {
          setLoading(false); 
        }
      }
    };

    fetchLikeStatus();
  }, [isLoggedIn, postId]);

  const handleLikeClick = async () => {
    if (!isLoggedIn || loading) {
      return; 
    }

    setLoading(true);

    try {
      const newLikeCount = !isLikedState ? likeCountState + 1 : Math.max(0, likeCountState - 1);
      await toggleLike(String(postId), !isLikedState);
      setIsLikedState(!isLikedState);
      setLikeCountState(newLikeCount);
    } catch (error) {
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="w-full top-0 bottom-0 right-0 left-0 fixed flex bg-[#111827e6] items-center z-10 justify-center">
          <Loader />
        </div>
      )}
      {error && (
        <Alert
          type="error"
          children={error}
          onClose={() => setError("")}
        />
      )}
      <PostCardLikeAction
        {...args}
        isLiked={isLikedState}
        likeCount={likeCountState}
        postId={postId}
        onClickLike={handleLikeClick}
      />
    </>
  );
};

export default PostCardLikeContainer;
